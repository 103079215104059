import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ptBr from "../../config/texts/pt-br";
import images from "../../config/images";
import {
  ButtonContained,
  CheckBox,
  CustomText,
  InputEmail,
  InputPassword,
} from "../../genericComponents";
import Styles from "./LoginStyles";
import { FormFull } from "form-full";
import { MediaQueries, Spacing } from "../../config";
import { validations } from "../../utils";
import { api } from "../../services";
import { hooks, SessionStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";
import ImageLogin from "./image/imageLogin";
import ImageLogin2 from "./image/imageLogin2";
import packageJson from "../../../package.json";
import fonts from "../../config/fonts";
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Icons } from "../../config";

import './login.css';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  cabecaAcordeao: {
    backgroundColor: Colors.azulEcom,
    color: Colors.white
  },
  tituloAcordeao: {
    color: Colors.white
  },
  corpoAcordeao: {
    marginTop: Spacing(3)
  },
  labelFiltro: {
    color: Colors.black
  },
  MenuItem: {
    '&:hover:not($disabled):not($focused):not($error):before': {
      color: Colors.white
    },
    '&$selected': {
      color: Colors.white
    }
  },
  paper: {
    position: 'absolute',
    width: '40rem',
    maxWidth: '100%',
    maxHeight: '95%',
    overflow: 'auto',
  },
  paper2: {
    width: '94%',
    maxWidth: '100%',
    overflow: 'auto',
    padding: theme.spacing(2, 4, 3),
    backgroundColor: 'white',
    borderRadius: '23px',
    maxHeight: '100%',
    marginLeft: '23px',
    textAlign: 'center',
    fontSize: '18px'

  }
}));

function Login() {
  const texts = ptBr.login;
  const version = packageJson.version;
  const navigate = useNavigate();
  const classes = useStyles();
  const { loading, call } = hooks.useRequest();
  const { addData, setIsLogged } = useContext(StorageContext);

  const [open, setOpen] = React.useState(null);
  const [openAtention, setOpenAtention] = React.useState(true);
  const [modalStyle] = React.useState(getModalStyle);

  const handleCloseAtention = () => {
    setOpenAtention(false);
  };

  const Submit = async (data) => {
    call(null, api.getToken(data), (response) => {
      console.log(response);
      if (response.ok) {
        
        console.log("JAVA TOKEN: " + response?.data?.type + " " + response?.data?.token);

        call(null, api.pegaToken(), (resposta) => {
          if(resposta.ok) {
            console.log("LARAVEL TOKEN: " + resposta?.data?.token_type + " " + resposta?.data?.access_token);
            SessionStorage.setItem(
              "middleware_token",
              resposta?.data?.token_type + " " + resposta?.data?.access_token
            );
          }
          call(null, api.verificaStatusParceiro(response.data.user.cpfCnpj, response.data.user.email), (ativo) => {
            if(ativo.ok) {
              if(ativo.data == 'ATIVO') {
                setIsLogged(true);
                console.log(response?.data?.user);
                addData("userData", response?.data?.user);
                SessionStorage.setItem(
                  "token",
                  response?.data?.type + " " + response?.data?.token
                );
                SessionStorage.setItem(
                  "email",
                  response?.data?.user.email
                );
                SessionStorage.setItem(
                  "user_id",
                  response?.data?.user.id
                );
                SessionStorage.setItem(
                  "avisoAssinatura",
                  'SIM'
                );
              } else {
                toastOps('O Parceiro com o CNPJ '+ response.data.user.cpfCnpj + ' está inativo, entre em contato com o administrador do sistema.');      
              }
            } else {
              toastOps('Ocorreu um erro ao validar o status do parceiro, entre em contato com o administrador do sistema.');      
            }
          });
        });
      } else{
        toastOps(response);
      }
    });
  };

  const [reminder, setReminder] = React.useState(false);
  const [buttonDisabled, setButton] = React.useState(true);

  const toastOps = (message) => {
    toast.warning(message, {
        position: toast.POSITION.TOP_CENTER
    });
  };

  return (
    <div className="bgfundo">

      <FormFull onSubmit={Submit} >
        <Grid
          container
          spacing={0}
          style={{
            height: "100vh",
            overflowX: "auto",
            display: "flex",
            flexDirection: "row",
            [MediaQueries.mdDown]: {
              flexDirection: "row-reverse",
            },
          }}>
          <Grid item xs={12} sm={5} style={{ overflowX: "hidden", overflow: "hidden" }} className="caixalogin">
            <Styles.Container className="formdelogin" >
              <div className="box-login">
                <Styles.EcomLogo src={images.logonovo} className="logonovo" />
                <h1 className="bemvindo">Seja bem-vindo,
                  <br/><span>agente Ecom!</span></h1>
                <p className="paragrafo-bemvindo">Entre aqui com seus dados para acessar o sistema!</p>  
                <InputEmail
                  name="username"
                  label={texts.user}
                  required={texts.userMessage}
                  classe="inputnovo"
                />
                <InputPassword
                  name="password"
                  label={texts.password}
                  required={texts.passwordMessage}
                  validation={validations.isPassword}
                  className="inputnovo"
                  onChange={() => {
                    setButton(false);
                  }}
                />
                <CheckBox
                  actualValue={reminder}
                  name="reminder"
                  label={texts.reminder}
                  click={() => setReminder(!reminder)}
                />
                <Grid
                  container
                  spacing={0}
                >
                    <Grid item xs={6} sm={6}>
                      <ButtonContained
                      name="submit"
                      loading={loading}
                      disabled={buttonDisabled}
                      feedback={true}
                      action="submit"
                      type="submit"
                      label={texts.enter}
                      className="botaonovo"
                    />
                    </Grid>
                    <Grid className="gridbotoes" item xs={6} sm={6}>
                            <Button
                              variant="contained"
                              onClick={() => navigate("/user_register")}
                              className="botaoacoes"
                              >
                                Ainda não tem conta? Crie aqui
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => navigate("/forgetPassword")}
                              className="botaoacoes"
                              >
                                Esqueci minha senha
                            </Button>

                        
                    </Grid>

                </Grid>
                  
                
                
                <Styles.Version>
                  <CustomText
                    fontSize={1.5}
                    fontFamily={fonts.light}
                    styles={{ color: "#C2C2C2", marginTop: Spacing(2) }}>
                    Parceiro Ecom v.{version} {process.env.REACT_APP_ENV}
                  </CustomText>
                </Styles.Version>
              </div>
            </Styles.Container>
          </Grid>

          <Grid item xs={0} sm={7} className="gridcarafalando">
            <ImageLogin2 />
            {/* <img src={images.falandocelular} className="imagemfalando" /> */}
          </Grid>
        </Grid>
      </FormFull>
      <Modal
              open={openAtention}
              onClose={handleCloseAtention}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              disableScrollLock={false}
              className="modalatention"
          >
          <>
            <div style={modalStyle} className={classes.paper}>
              <img src={images.iconeatencao} className="iconeatencao"  />
              <div className={classes.paper2}>
                <h3 className="h3atention">Atualização Importante: Desativação do Portal</h3>
                <p align="justify">Prezados Parceiros Ecom,<br />
                Gostaríamos de informá-los sobre a desativação de uma das ferramentas que utilizamos atualmente. Estamos investindo em uma nova plataforma mais moderna e eficiente, que tem como objetivo aprimorar a experiência de todos vocês e garantir um atendimento ainda mais ágil e de excelência.
                <br />Essa mudança faz parte do nosso compromisso contínuo em oferecer soluções mais robustas e alinhadas às necessidades de nossos parceiros. A transição será realizada de forma gradual e, em breve, compartilharemos mais detalhes sobre a nova plataforma e como ela trará benefícios diretos para o seu trabalho conosco.
                <br />Dúvidas, contate o seu consultor.
                <br />Agradecemos a compreensão e continuamos à disposição para quaisquer dúvidas.
                <br /><br />Atenciosamente, Time Ecom</p>
                <Button 
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleCloseAtention();
                    }}
                    className="fecharatencao"
                >
                  Fechar
                </Button>
              </div>
            </div>
          </>
          </Modal>
      <ToastContainer />
    </div>
  );
}

export default Login;
